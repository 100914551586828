/* eslint-disable -- Required by lib */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ContentBlockDto {
  type: string;
  data: Record<string, any>;
  tunes?: Record<string, any>;
}

export interface UpdateDraftDto {
  title?: string;
  content?: ContentBlockDto[];
  languages?: ('en' | 'ru')[];
  tags?: string[];
  /** @format date-time */
  publishedAt?: string | null;
  secret?: string;
}

export interface DraftDto {
  id: string;
  secret: string;
  postSlug: string | null;
  postTitle: string | null;
  title: string | null;
  postContent: ContentBlockDto[];
  content: ContentBlockDto[];
  postLanguages: ('en' | 'ru')[];
  languages: ('en' | 'ru')[];
  postTags: string[];
  tags: string[];
  published: boolean;
  postPublished: boolean;
  /** @format date-time */
  publishedAt: string | null;
  /** @format date-time */
  postPublishedAt: string | null;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  postUpdatedAt: string;
  /** @format date-time */
  createdAt: string;
}

export interface ResponseDto {
  statusCode: number;
  message: string;
}

export interface ValidationErrorDto {
  statusCode: number;
  message: string;
  /** @example {"foo":{"bar":[{"message":"This field is invalid","key":"isString"}]},"bar":[{"message":"This field is invalid","key":"isString"}]} */
  errors:
    | {
        message?: string;
        key?: string;
      }[]
    | object;
}

export interface CreateDraftDto {
  title?: string;
  content?: ContentBlockDto[];
  languages: ('en' | 'ru')[];
  tags?: string[];
  /** @format date-time */
  publishedAt?: string | null;
}

export interface DraftsDto {
  total: number;
  drafts: DraftDto[];
}

export interface PostDto {
  id: string;
  slug: string | null;
  title: string | null;
  views: number;
  content: ContentBlockDto[];
  /** @format date-time */
  publishedAt: string;
  /** @format date-time */
  updatedAt: string;
  languages: ('en' | 'ru')[];
  tags: string[];
}

export interface PostsDto {
  total: number;
  posts: PostDto[];
}

export interface AccessTokenDto {
  accessToken: string;
  /** @format date-time */
  expiresAt: string;
}

export interface LoginDto {
  email: string;
  password: string;
}

export interface UserDto {
  id: string;
  email: string;
  /** @format date-time */
  createdAt: string;
}

export interface UploadUrlDto {
  fileUrl: string;
  uploadUrl: string;
  fields: Record<string, any>;
}

export interface LinkMetaImageDto {
  url: string;
}

export interface LinkMetaDto {
  title?: string;
  description?: string;
  image?: LinkMetaImageDto;
}

export interface LinkPreviewDto {
  success: boolean;
  meta: LinkMetaDto;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title lsndr api
 * @version 1.0
 * @contact
 */
export class Client<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  blog = {
    /**
     * No description
     *
     * @tags Blog Drafts
     * @name UpdateDraft
     * @request PUT:/blog/drafts/{id}
     * @secure
     * @response `200` `DraftDto`
     * @response `403` `ResponseDto`
     * @response `404` `ResponseDto`
     * @response `422` `ValidationErrorDto`
     */
    updateDraft: (id: string, data: UpdateDraftDto, params: RequestParams = {}) =>
      this.request<DraftDto, ResponseDto | ValidationErrorDto>({
        path: `/blog/drafts/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Drafts
     * @name FindDraftById
     * @request GET:/blog/drafts/{id}
     * @secure
     * @response `200` `DraftDto`
     * @response `403` `ResponseDto`
     * @response `404` `ResponseDto`
     */
    findDraftById: (
      id: string,
      query?: {
        /** Provide secret if request is no authorization token is provided */
        secret?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DraftDto, ResponseDto>({
        path: `/blog/drafts/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Drafts
     * @name RemoveDraft
     * @request DELETE:/blog/drafts/{id}
     * @secure
     * @response `200` `void`
     * @response `403` `ResponseDto`
     * @response `404` `ResponseDto`
     */
    removeDraft: (id: string, params: RequestParams = {}) =>
      this.request<void, ResponseDto>({
        path: `/blog/drafts/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Drafts
     * @name CreateDraft
     * @request POST:/blog/drafts
     * @secure
     * @response `200` `DraftDto`
     * @response `403` `ResponseDto`
     * @response `404` `ResponseDto`
     * @response `422` `ValidationErrorDto`
     */
    createDraft: (data: CreateDraftDto, params: RequestParams = {}) =>
      this.request<DraftDto, ResponseDto | ValidationErrorDto>({
        path: `/blog/drafts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Drafts
     * @name FindDrafts
     * @request GET:/blog/drafts
     * @secure
     * @response `200` `DraftsDto`
     * @response `403` `ResponseDto`
     * @response `422` `ValidationErrorDto`
     */
    findDrafts: (
      query: {
        limit: number;
        offset: number;
        tags?: string[];
        languages?: ('en' | 'ru')[];
      },
      params: RequestParams = {},
    ) =>
      this.request<DraftsDto, ResponseDto | ValidationErrorDto>({
        path: `/blog/drafts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Drafts
     * @name PublishDraft
     * @request DELETE:/blog/drafts/{id}/publish
     * @secure
     * @response `200` `DraftDto`
     * @response `403` `ResponseDto`
     * @response `404` `ResponseDto`
     */
    publishDraft: (id: string, params: RequestParams = {}) =>
      this.request<DraftDto, ResponseDto>({
        path: `/blog/drafts/${id}/publish`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Drafts
     * @name UnpublishDraft
     * @request DELETE:/blog/drafts/{id}/unpublish
     * @secure
     * @response `200` `DraftDto`
     * @response `403` `ResponseDto`
     * @response `404` `ResponseDto`
     */
    unpublishDraft: (id: string, params: RequestParams = {}) =>
      this.request<DraftDto, ResponseDto>({
        path: `/blog/drafts/${id}/unpublish`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Drafts
     * @name ResetDraft
     * @request POST:/blog/drafts/{id}/reset
     * @secure
     * @response `200` `DraftDto`
     * @response `403` `ResponseDto`
     * @response `404` `ResponseDto`
     */
    resetDraft: (id: string, params: RequestParams = {}) =>
      this.request<DraftDto, ResponseDto>({
        path: `/blog/drafts/${id}/reset`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Posts
     * @name FindPostById
     * @request GET:/blog/posts/{id}
     * @response `200` `PostDto`
     * @response `404` `ResponseDto`
     */
    findPostById: (id: string, params: RequestParams = {}) =>
      this.request<PostDto, ResponseDto>({
        path: `/blog/posts/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Posts
     * @name ViewPost
     * @request POST:/blog/posts/{id}/view
     * @response `200` `void`
     * @response `404` `ResponseDto`
     */
    viewPost: (id: string, params: RequestParams = {}) =>
      this.request<void, ResponseDto>({
        path: `/blog/posts/${id}/view`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blog Posts
     * @name FindPosts
     * @request GET:/blog/posts
     * @response `200` `PostsDto`
     * @response `422` `ValidationErrorDto`
     */
    findPosts: (
      query: {
        limit: number;
        offset: number;
        tags?: string[];
        languages?: ('en' | 'ru')[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PostsDto, ValidationErrorDto>({
        path: `/blog/posts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name GetAccessToken
     * @request POST:/auth/access-token
     * @response `200` `AccessTokenDto`
     */
    getAccessToken: (params: RequestParams = {}) =>
      this.request<AccessTokenDto, any>({
        path: `/auth/access-token`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Login
     * @request POST:/auth/login
     * @response `200` `AccessTokenDto`
     * @response `422` `ValidationErrorDto`
     */
    login: (data: LoginDto, params: RequestParams = {}) =>
      this.request<AccessTokenDto, ValidationErrorDto>({
        path: `/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Logout
     * @request DELETE:/auth/logout
     * @secure
     * @response `200` `ResponseDto`
     * @response `403` `ResponseDto`
     * @response `404` `ResponseDto`
     */
    logout: (params: RequestParams = {}) =>
      this.request<ResponseDto, ResponseDto>({
        path: `/auth/logout`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags User
     * @name GetMe
     * @request GET:/user
     * @secure
     * @response `200` `UserDto`
     * @response `403` `ResponseDto`
     */
    getMe: (params: RequestParams = {}) =>
      this.request<UserDto, ResponseDto>({
        path: `/user`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name GetUploadImageUrl
     * @request GET:/files/upload/image
     * @secure
     * @response `200` `UploadUrlDto`
     * @response `403` `ResponseDto`
     */
    getUploadImageUrl: (params: RequestParams = {}) =>
      this.request<UploadUrlDto, ResponseDto>({
        path: `/files/upload/image`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  fetch = {
    /**
     * No description
     *
     * @tags Fetch
     * @name GetLinkPreview
     * @request GET:/fetch/link
     * @secure
     * @response `200` `LinkPreviewDto`
     * @response `403` `ResponseDto`
     */
    getLinkPreview: (
      query: {
        url: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LinkPreviewDto, ResponseDto>({
        path: `/fetch/link`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
