import { createContext } from 'react';
import { Client } from './client';

export type Token = {
  accessToken: string;
  expiresAt: Date;
};

export type ClientSecurityData = {
  token: string;
};

type Context = {
  client: Omit<Client<ClientSecurityData>, 'setSecurityData'>;
  getToken(this: void): Token | null;
  isTokenExpired: (this: void) => boolean;
  hasToken: boolean;
  setToken: (this: void, token: Token | null) => void;
  refreshToken: (this: void) => Promise<void>;
};

export const ApiContext = createContext<Context | undefined>(undefined);
