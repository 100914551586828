import { Client } from './client';
import { useContext } from 'react';
import { ApiContext, ClientSecurityData } from './api-context';

export function useApi(): Omit<Client<ClientSecurityData>, 'setSecurityData'> {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('Api context is not provided');
  }

  return context.client;
}
