import {
  MutationFunction,
  MutationKey,
  UseMutationOptions,
  useMutation,
} from 'react-query';
import { useApiCredentials } from './use-api-credentials';

export function useApiMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationKey: MutationKey,
  mutationFn?: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  >,
) {
  const { refreshToken, isTokenExpired } = useApiCredentials();
  let mutation: MutationFunction<TData, TVariables> | undefined;

  if (mutationFn) {
    mutation = async (data) => {
      if (isTokenExpired()) {
        await refreshToken();
      }

      return mutationFn(data);
    };
  }

  return useMutation<TData, TError, TVariables, TContext>(
    mutationKey,
    mutation,
    options,
  );
}
