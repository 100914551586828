import '../styles/globals.scss';
import '../styles/editorjs.scss';
import '../styles/zoom.scss';
import 'react-medium-image-zoom/dist/styles.css';
import '../styles/react-select.scss';
import 'react-tooltip/dist/react-tooltip.css';
import Head from 'next/head';
import { FunctionComponent, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ApiProvider } from '../features/shared/api';
import Script from 'next/script';

type Props = {
  Component: FunctionComponent<Record<string, unknown>>;
  pageProps: Record<string, unknown>;
};

function App({ Component, pageProps }: Props) {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry(failureCount, error) {
              if (
                error instanceof Response &&
                error.status >= 400 &&
                error.status <= 499
              ) {
                return false;
              }

              return failureCount < 3;
            },
          },
          mutations: {
            retry(failureCount, error) {
              if (
                error instanceof Response &&
                error.status >= 400 &&
                error.status <= 499
              ) {
                return false;
              }

              return failureCount < 3;
            },
          },
        },
      }),
    [],
  );

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <Script id="yametrika">{`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    ym(75637261, "init", {
         clickmap:true,
         trackLinks:true,
         accurateTrackBounce:true
    });`}</Script>

      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <div>
           <img src='https://mc.yandex.ru/watch/75637261'
                style={{ position: 'absolute', left: '-9999px' }}
                alt='' />
         </div>
       `,
        }}
      />
      <ApiProvider>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
        </QueryClientProvider>
      </ApiProvider>
    </>
  );
}

export default App;
