import {
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from 'react-query';
import { useApiCredentials } from './use-api-credentials';

export function useApiInfiniteQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey
    >,
    'queryKey' | 'queryFn'
  >,
) {
  const { refreshToken, isTokenExpired } = useApiCredentials();

  return useInfiniteQuery(
    queryKey,
    async (data) => {
      if (isTokenExpired()) {
        await refreshToken();
      }

      return queryFn(data);
    },
    options,
  );
}
