import {
  ReactNode,
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import { ApiContext, ClientSecurityData, Token } from './api-context';
import { Client } from './client';

type Props = {
  children: ReactNode;
};

export function ApiProvider(props: Props) {
  const { children } = props;

  const setToken = useCallback((token: Token | null) => {
    if (token === null) {
      localStorage.removeItem('token');
      setHasToken(false);
    } else {
      localStorage.setItem('token', JSON.stringify(token));
      setHasToken(true);
    }
  }, []);

  const getToken = useCallback(() => {
    const storedToken =
      typeof localStorage === 'undefined'
        ? null
        : localStorage.getItem('token');

    if (!storedToken) {
      return null;
    }

    const token = JSON.parse(storedToken);
    token.expiresAt = new Date(token.expiresAt);

    return token as Token;
  }, []);

  const isTokenExpired = useCallback(() => {
    const token = getToken();

    if (!token) {
      return false;
    }

    return Date.now() > token.expiresAt.getTime() - 60 * 1000;
  }, [getToken]);

  const [hasToken, setHasToken] = useState(!!getToken());

  useEffect(() => {
    if (!hasToken) {
      refreshToken().catch(console.error);
    }
  });

  const client = useMemo(() => {
    return new Client<ClientSecurityData>({
      baseUrl: process.env['NEXT_PUBLIC_API_URL'],
      baseApiParams: {
        credentials: 'include',
        secure: true,
      },
      securityWorker: function () {
        const headers: Record<string, string> = {};
        const token = getToken();

        if (token) {
          headers['Authorization'] = `Bearer ${token.accessToken}`;
        }

        return {
          headers,
        };
      },
    });
  }, [getToken]);

  const refreshTokenResult = useRef<Promise<unknown>>();

  const refreshToken = useCallback(async () => {
    if (refreshTokenResult.current) {
      await refreshTokenResult.current;
      return;
    }

    refreshTokenResult.current = client.auth
      .getAccessToken()
      .then(({ data }) => {
        const expiresAt = new Date(data.expiresAt);

        setToken({
          accessToken: data.accessToken,
          expiresAt,
        });

        refreshTokenResult.current = undefined;
      });

    await refreshTokenResult.current;
  }, [client, setToken]);

  return (
    <ApiContext.Provider
      value={{
        client,
        setToken,
        getToken,
        isTokenExpired,
        refreshToken,
        hasToken,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
